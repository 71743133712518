import {useRef, useEffect} from 'react';
const useBlurOnFulfill = ({value, cellCount}) => {
  const inputRef = useRef(null);
  useEffect(() => {
    if (value && value.length === cellCount) {
      const {current: inputInstance} = inputRef;
      if (inputInstance) {
        inputInstance.blur();
      }
    }
  }, [value, cellCount]);
  return inputRef;
};
export default useBlurOnFulfill;
